main {
  background: #e8f3f5;
  min-height: calc(100% - 289px);
}

.wrapper {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  max-width: 1280px;
  min-width: 800px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  main {
    min-height: calc(100% - 283px);
  }
}

@media only screen and (max-width: 900px) {
  .container {
    width: 100%;
    min-width: 0px;
  }
}
