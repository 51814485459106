.button {
  padding: 12px 28px;
  color: white;
  border-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  background: #6ab8c6;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
}
.button:hover {
  cursor: pointer;
  transform: scale(1.02);
  background-color: #55909b;
  transform-origin: bottom;
}
