.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1em;
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.pagination li {
  cursor: pointer;
  padding: 15px;
  width: 62px;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #6ab8c6;
  border-radius: 0.5em;
  transition: 0.15s;
  background-color: white;
}
