.title {
  font-size: 36px;
  margin: 40px auto;
  font-weight: bold;
  color: #2fb3ca;
  border: 2px solid #6ab8c6;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 1em;
  padding: 20px;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  background: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.title :hover {
  text-decoration: underline;
}

@media only screen and (max-width: 400px) {
  .title {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-left: 10px;
    padding-right: 10px;
    word-wrap: break-word;
    inline-size: 90vw;
  }
}