.card_wrapper {
  margin-bottom: 40px;
}
.card_wrapper :hover {
  cursor: pointer;
  transform: scale(1.02);
  background-color: rgb(248, 248, 248);
  transform-origin: bottom;
}

.card {
  padding: 20px;
  display: grid;
  grid-template-columns: 100px 100px 160px 100px 190px;
  gap: 1em;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  border: 4px solid #6ab8c6;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: bold;
  font-size: 19px;
}

@media only screen and (max-width: 900px) {
  .card {
    grid-template-columns: auto;
    display: grid;
    grid-template-rows: auto auto auto;
    align-items: center;
    justify-content: center;
  }
}
.status {
  width: 100%;
  margin-left: auto;
  margin-right: 0px;
  padding: 10px;
  background-color: #6ab8c6;
  color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #6ab8c6;
}
