.footer {
  box-shadow: 0px -10px 10px rgba(107, 165, 252, 0.2);
  display: flex;
  align-items: center;
  height: 30px;
  padding: 3px;
  width: 100%;
  background-color: #6ab8c6;
  color: white;
}

.footer > p {
  margin-right: 0;
  margin-left: auto;
  padding-right: 10px;
}
