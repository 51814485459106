.label {
  padding: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  background: #6ab8c6;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 1em;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  height: 50px;
}

@media only screen and (max-width: 800px) {
  .label {
    flex: none;
  }
}
