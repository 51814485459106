.card {
  margin-bottom: 40px;
  padding: 20px;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 4px solid #6ab8c6;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  transition: 0.15s;
  background-color: white;
}

.description {
  margin-left: 0;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 1em;
  font-size: 20px;
}

.title {
  font-weight: bold;
  margin-left: 10px;
}

.title :hover {
  text-decoration: underline;
}

.activity {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.activity a {
  cursor: pointer;
}
