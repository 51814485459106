.select {
  border: 3px solid #6ab8c6;
  flex: 1;
  padding: 10px;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  background-color: white;
}
.select:disabled {
  color: black;
  cursor: default;
  background-color: #e8f3f5;
}
